import { cn } from "@/lib/utils";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import copy from "copy-to-clipboard";
import { toast } from "sonner";

export default function CopyToClipboard({
  text,
  showIcon,
  className,
  textToCopy,
  hideText,
  iconOnlyClick,
}: {
  text: string;
  className?: string;
  hideText?: boolean;
  textToCopy?: string;
  showIcon?: boolean;
  iconOnlyClick?: boolean;
}) {
  function copyClicked(e: React.MouseEvent<HTMLDivElement>) {
    if (iconOnlyClick) return;
    e.stopPropagation();
    e.preventDefault();
    copy(textToCopy ?? text);
    toast.success("Text copied to clipboard");
  }

  function iconClicked(e: React.MouseEvent<SVGSVGElement>) {
    e.stopPropagation();
    e.preventDefault();
    copy(textToCopy ?? text);
    toast.success("Text copied to clipboard");
  }

  return (
    <div
      onClick={copyClicked}
      className={cn(
        "group cursor-pointer inline-flex relative space-y-2",
        className,
        iconOnlyClick && "cursor-default"
      )}
    >
      {!hideText && text}
      {showIcon && (
        <ClipboardIcon
          onClick={iconClicked}
          className="h-5 cursor-pointer w-5 absolute rounded-md text-muted-foreground -right-6 hidden hover:text-primary group-hover:block"
        />
      )}
    </div>
  );
}
