import { AppLayout } from "@/components/app-layout";
import CopyToClipboard from "@/components/copy-to-clipboard";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { apiClient } from "@/lib/api";
import { useDebounce } from "@/lib/utils";
import { queryOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/admin/file-enrichment")({
  component: () => <FileEnrichment />,
});

async function getHash(hash?: string) {
  if (hash == null || hash.length == 0) return;
  const response = await apiClient.GET(
    `/admin/integration/reversing-labs/enrich/{hash}`,
    {
      params: {
        path: { hash },
      },
    }
  );
  if (response.error != null) {
    toast.error("No data found");
    throw new Error("Error enriching file");
  }
  return response.data;
}

const HASH_QUERY_KEY = "admin-file-enrichment-hash";
function getHashOptions(hash?: string) {
  return queryOptions({
    queryKey: [HASH_QUERY_KEY, hash],
    queryFn: () => getHash(hash),
    retry: false,
  });
}

function FileEnrichment() {
  const [hash, setHash] = useState("");
  const debouncedHash = useDebounce(500, hash);
  const hashQuery = useQuery(getHashOptions(debouncedHash.debounced));
  const queryClient = useQueryClient();

  return (
    <AppLayout>
      <div className="grid  items-start gap-4 ">
        <Card>
          <CardHeader>
            <CardTitle>Search File Analysis</CardTitle>
            <CardDescription>Get rich details for file hashes</CardDescription>
          </CardHeader>
          <CardContent>
            <Input
              value={hash}
              onChange={(e) => setHash(e.target.value)}
              placeholder="Enter a sha1 or sha256 file hash"
            />
            {hashQuery.data != null && (
              <div className="group relative">
                <pre className="p-2 max-h-[800px] overflow-auto mt-4 rouned-md bg-muted">
                  {JSON.stringify(hashQuery.data, null, 2)}
                </pre>
                <CopyToClipboard
                  className="absolute top-2 right-2"
                  text={JSON.stringify(hashQuery.data, null, 2)}
                  hideText
                  showIcon
                />
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </AppLayout>
  );
}
