import { ActionEdgeProps } from "@/components/flow/editor/edge/action-edge-editor";
import { ActionNodeProps } from "@/components/flow/editor/node/action-node-editor";
import { getAllAncestorNodesInFlow } from "@/components/flow/flow.utils";
import {
  BaseCustomNode,
  BaseCustomNodeComponentProps,
} from "@/components/flow/node/base-custom-node";
import {
  FlowNodeType,
  DetectionCategoryConfig,
  DetectionSubCategoryConfig,
  Verdict,
} from "@wire/shared";
import { Edge, Node, NodeProps, useEdges, useNodes } from "@xyflow/react";
import { memo, useMemo } from "react";

export interface ActionNodeCompProps extends BaseCustomNodeComponentProps {}

function ActionNode(
  props: NodeProps<Node<ActionNodeProps>> & ActionNodeCompProps
) {
  const edges = useEdges();
  const nodes = useNodes();
  let footer = useMemo(() => {
    let out: string[] = [];
    if (props.data.contain) {
      out.push("contain");
    }
    if (props.data.chatOps) {
      out.push("chat ops");
    }
    if (props.data.escalate) {
      out.push("escalate");
    }
    if (props.data.close) {
      out.push("close");
    }
    return out.join(" | ");
  }, [props.data]);

  let previousActionNode = useMemo(() => {
    let allNodesInFlow = getAllAncestorNodesInFlow(nodes, edges, props.id);
    return allNodesInFlow.some((n) => n.type == FlowNodeType.ACTION);
  }, [nodes, edges, props.id]);

  let warnings = useMemo(() => {
    let out: string[] = [];
    if (props.data.subcategory == null) {
      out.push("subcategory is required");
    }
    let nodeEdges = edges.filter((e) => e.source == props.id);
    if (props.data.chatOps) {
      if (
        !nodeEdges.some(
          (e: Edge<ActionEdgeProps>) => e.data?.verdict == Verdict.MALICIOUS
        )
      ) {
        out.push("No edge for malicious verdict");
      }
      if (
        !nodeEdges.some(
          (e: Edge<ActionEdgeProps>) => e.data?.verdict == Verdict.BENIGN
        )
      ) {
        out.push("No edge for benign verdict");
      }
      if (
        !nodeEdges.some(
          (e: Edge<ActionEdgeProps>) => e.data?.verdict == Verdict.SUSPICIOUS
        )
      ) {
        out.push("No edge for suspicious verdict");
      }

      if (previousActionNode) {
        out.push("Chat ops has already been performed for this node");
      }
    }
    if (
      (props.data.close ?? false) == false &&
      (props.data.escalate ?? false) == false &&
      (props.data.contain ?? false) == false &&
      (props.data.chatOps ?? false) == false
    ) {
      out.push("Action node must have an action");
    }

    if (
      props.data.subcategory == null ||
      DetectionSubCategoryConfig[props.data.subcategory] == null
    ) {
      out.push("Update subcategory");
    }

    if (
      props.data.category == null ||
      DetectionCategoryConfig[props.data.category] == null
    ) {
      out.push("Update subcategory");
    }

    return out;
  }, [props.data, edges, previousActionNode]);

  const isConnectableStart = useMemo(() => {
    return (
      edges.filter((e: Edge<ActionEdgeProps>) => e.source == props.id).length <
      3
    );
  }, [edges, nodes, props.id]);

  const noEndHandle = useMemo(() => {
    return !props.data.chatOps || previousActionNode;
  }, [props.data.chatOps, previousActionNode]);

  const label = useMemo(() => {
    if (
      props.data.subcategory != null &&
      props.data.category != null &&
      (props.data.category as any) == (props.data.subcategory as any)
    ) {
      return DetectionSubCategoryConfig[props.data.subcategory]?.display;
    }
    let categoryDisplay =
      props.data.category != null
        ? DetectionCategoryConfig[props.data.category]?.display
        : null;
    let subcategoryDisplay =
      props.data.subcategory != null
        ? DetectionSubCategoryConfig[props.data.subcategory]?.display
        : null;
    return [categoryDisplay, subcategoryDisplay].filter(Boolean).join(" - ");
  }, [props.data.category, props.data.subcategory]);

  return (
    <BaseCustomNode
      footer={footer}
      warnings={warnings}
      noEndHandle={noEndHandle}
      isConnectableStart={isConnectableStart}
      {...props}
      data={{ ...props.data, type: FlowNodeType.ACTION }}
    >
      {props.data.subcategory && <div className="text-xs">{label}</div>}
    </BaseCustomNode>
  );
}

let ActionNodeMemo = memo(ActionNode);

export { ActionNodeMemo as ActionNode };
