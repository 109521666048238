import { AppLayout } from "@/components/app-layout";
import ManageExclusion from "@/components/manage-exclusion";
import { TableCard } from "@/components/table-card";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { apiClient, formatSearchQuery } from "@/lib/api";
import { components } from "@/lib/api.types";
import { dateTime } from "@/lib/time";
import {
  CheckIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { showArticle } from "@intercom/messenger-js-sdk";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import {
  createFileRoute,
  useNavigate,
  useRouteContext,
} from "@tanstack/react-router";
import {
  createDetectionQuery,
  DETECTION_QUERY_FIELDS,
  DOCS,
  getStatusConfigByStatus,
  QUERY_OPERATOR,
  ROLE,
} from "@wire/shared";
import { useMemo, useState } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_application/assets/users/$userId")({
  component: User,
  loader: async ({ params, context }) => {
    const response = await context.queryClient.ensureQueryData(
      getOptions(params.userId)
    );
    context.title = response.displayName;
  },
});

async function getData(userId: string) {
  const user = await apiClient.GET("/directory/{id}", {
    params: { path: { id: userId } },
  });
  if (user.error != null) {
    throw new Error("Error getting user information");
  }
  return user.data;
}

export const ASSET_QUERY_KEY = "user";
const getOptions = (caseId: string) =>
  queryOptions({
    queryKey: [ASSET_QUERY_KEY, caseId],
    queryFn: () => getData(caseId),
  });

async function searchIPs(
  searchSettings: components["schemas"]["SearchIPDto"],
  userId: string
) {
  let search = formatSearchQuery(searchSettings.search);
  const [cases] = await Promise.all([
    apiClient.POST("/ip", {
      body: { ...searchSettings, search, userId },
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting IP information");
  }
  return cases.data;
}
export const IP_QUERY_KEY = "user-ips";
const getSearchIPsOptions = (
  searchSettings: components["schemas"]["SearchIPDto"],
  userId: string
) =>
  queryOptions({
    queryKey: [IP_QUERY_KEY, searchSettings, userId],
    queryFn: () => searchIPs(searchSettings, userId),
    placeholderData: keepPreviousData,
  });

async function searchEndpoints(
  searchSettings: components["schemas"]["EndpointSearchDto"],
  userId: string
) {
  const [cases] = await Promise.all([
    apiClient.POST("/endpoint", {
      body: { ...searchSettings, userId },
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting endpoint information");
  }
  return cases.data;
}
export const ENDPOINT_QUERY_KEY = "user-ips";
const getSearchEndpointsOptions = (
  searchSettings: components["schemas"]["EndpointSearchDto"],
  userId: string
) =>
  queryOptions({
    queryKey: [ENDPOINT_QUERY_KEY, searchSettings, userId],
    queryFn: () => searchEndpoints(searchSettings, userId),
    placeholderData: keepPreviousData,
  });

async function searchUserAgents(
  searchSettings: components["schemas"]["UserAgentSearchDto"],
  userId: string
) {
  const [userAgents] = await Promise.all([
    apiClient.POST("/user-agent", {
      body: { ...searchSettings, userId },
    }),
  ]);
  if (userAgents.error != null) {
    throw new Error("Error getting user agent information");
  }
  return userAgents.data;
}

export const USER_AGENT_QUERY_KEY = "user-agents";
const getSearchUserAgentsOptions = (
  searchSettings: components["schemas"]["UserAgentSearchDto"],
  userId: string
) =>
  queryOptions({
    queryKey: [USER_AGENT_QUERY_KEY, searchSettings, userId],
    queryFn: () => searchUserAgents(searchSettings, userId),
    placeholderData: keepPreviousData,
  });

async function searchCases(
  searchSettings: components["schemas"]["SearchCasesDto"],
  userId: string
) {
  const [cases] = await Promise.all([
    apiClient.POST("/cases", {
      body: { ...searchSettings, assetType: "USER", assetId: userId },
    }),
  ]);
  if (cases.error != null) {
    throw new Error("Error getting cases information");
  }
  return cases.data;
}
export const CASES_QUERY_KEY = "case-settings";
const getSearchCaseOptions = (
  searchSettings: components["schemas"]["SearchCasesDto"],
  userId: string
) =>
  queryOptions({
    queryKey: [CASES_QUERY_KEY, searchSettings, userId],
    queryFn: () => searchCases(searchSettings, userId),
    placeholderData: keepPreviousData,
  });

function User() {
  const { userId } = Route.useParams();
  const { integrationMetadata } = useRouteContext({
    from: "/_application",
  });
  const [createExclusionDialogOpen, setCreateExclusionDialogOpen] =
    useState(false);
  const [caseSearchSettings, setCaseSearchSettings] = useState<
    components["schemas"]["SearchCasesDto"]
  >({});
  const [endpointSearchSettings, setEndpointSearchSettings] = useState<
    components["schemas"]["EndpointSearchDto"]
  >({ userId });
  const [ipSearchSettings, setIPSearchSettings] = useState<
    components["schemas"]["SearchIPDto"]
  >({ field: "ipv4" });
  const [userAgentSearchSettings, setUserAgentSearchSettings] = useState<
    components["schemas"]["UserAgentSearchDto"]
  >({});
  const casesQuery = useQuery(getSearchCaseOptions(caseSearchSettings, userId));
  const ipsQuery = useQuery(getSearchIPsOptions(ipSearchSettings, userId));
  const endpointsQuery = useQuery(
    getSearchEndpointsOptions(endpointSearchSettings, userId)
  );
  const userAgentsQuery = useQuery(
    getSearchUserAgentsOptions(userAgentSearchSettings, userId)
  );
  const { data: user } = useSuspenseQuery(getOptions(userId));
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  async function updateUser(
    dto: components["schemas"]["UpdateDirectoryUserDto"]
  ) {
    const response = await apiClient.PATCH("/directory/{id}", {
      params: { path: { id: userId } },
      body: dto,
    });
    if (response.error != null) {
      toast.error(response.error.message);
    }
    await queryClient.invalidateQueries({
      queryKey: [ASSET_QUERY_KEY, userId],
    });
    toast.success("User updated");
  }

  async function refreshUser() {
    toast.loading("Refreshing user data...", { id: "refresh-user" });
    const response = await apiClient.POST("/directory/{id}/refresh", {
      params: { path: { id: userId } },
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    await queryClient.invalidateQueries({
      queryKey: [ASSET_QUERY_KEY, userId],
    });
    toast.dismiss("refresh-user");
    toast.success("User data refreshed");
  }

  const defaultExclusionQuery = useMemo(() => {
    let values: [string, string] = ["", ""];
    if (user.email != null) {
      values = [DETECTION_QUERY_FIELDS.USER_EMAIL, `"${user.email}"`];
    } else if (user.username != null) {
      values = [DETECTION_QUERY_FIELDS.USER_USERNAME, `"${user.username}"`];
    } else {
      return "";
    }
    return createDetectionQuery(
      values[0],
      QUERY_OPERATOR.ALL_ARRAY_VALUES_EQUAL,
      values[1]
    );
  }, []);

  return (
    <AppLayout>
      <ManageExclusion
        title="Create Exclusion"
        providedDetectionSid
        query={defaultExclusionQuery}
        name={user.displayName}
        detectionSid={casesQuery.data?.data[0]?.detectionSids[0]}
        description="Automatically close future detections that match this query"
        open={createExclusionDialogOpen}
        onClose={() => setCreateExclusionDialogOpen(false)}
      />
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader className="bg-muted/50 mb-4  space-y-0 lg:items-center flex-col gap-4 lg:flex-row lg:justify-between">
            <div>
              <CardTitle>{user.displayName}</CardTitle>
              <CardDescription>User</CardDescription>
            </div>
            <div className="flex flex-col gap-4 lg:flex-row">
              <DropdownMenu requiredRole={ROLE.ANALYST}>
                <DropdownMenuTrigger asChild>
                  <Button>Actions</Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem onClick={refreshUser}>
                    <div>
                      <h4 className="font-semibold">Refresh User Data</h4>
                      <p className="text-muted-foreground">
                        Fetch latest user data from directory source
                      </p>
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => setCreateExclusionDialogOpen(true)}
                  >
                    <div>
                      <h4 className="font-semibold">Create Exclusion</h4>
                      <p className="text-muted-foreground">
                        Automatically ignore detections that are associated with
                        this user
                      </p>
                    </div>
                  </DropdownMenuItem>
                  {user.vip && (
                    <DropdownMenuItem
                      onClick={() =>
                        updateUser({ vip: false, vipOverriddenByUser: true })
                      }
                    >
                      <div>
                        <h4 className="font-semibold">Remove VIP status</h4>
                        <p className="text-muted-foreground">
                          Stop providing special handling for detections
                          associated with this user
                        </p>
                      </div>
                    </DropdownMenuItem>
                  )}
                  {!user.vip && (
                    <DropdownMenuItem
                      onClick={() =>
                        updateUser({ vip: true, vipOverriddenByUser: true })
                      }
                    >
                      <div>
                        <h4 className="font-semibold">Set as VIP</h4>
                        <p className="text-muted-foreground">
                          Provide special handling for detections associated
                          with this user
                        </p>
                      </div>
                    </DropdownMenuItem>
                  )}
                  {user.technical && (
                    <DropdownMenuItem
                      onClick={() =>
                        updateUser({
                          technical: false,
                          technicalOverriddenByUser: true,
                        })
                      }
                    >
                      <div>
                        <h4 className="font-semibold">
                          Remove Technical status
                        </h4>
                        <p className="text-muted-foreground">
                          Stop allowing this user to run technical programs
                        </p>
                      </div>
                    </DropdownMenuItem>
                  )}
                  {!user.technical && (
                    <DropdownMenuItem
                      onClick={() =>
                        updateUser({
                          technical: true,
                          technicalOverriddenByUser: true,
                        })
                      }
                    >
                      <div>
                        <h4 className="font-semibold">Set as Technical</h4>
                        <p className="text-muted-foreground">
                          Allow this user to use common technical programs
                        </p>
                      </div>
                    </DropdownMenuItem>
                  )}
                  {user.administrator && (
                    <DropdownMenuItem
                      onClick={() =>
                        updateUser({
                          administrator: false,
                          administratorOverriddenByUser: true,
                        })
                      }
                    >
                      <div>
                        <h4 className="font-semibold">
                          Remove Administrator status
                        </h4>
                        <p className="text-muted-foreground">
                          Stop treating this user as an administrator
                        </p>
                      </div>
                    </DropdownMenuItem>
                  )}
                  {!user.administrator && (
                    <DropdownMenuItem
                      onClick={() =>
                        updateUser({
                          administrator: true,
                          administratorOverriddenByUser: true,
                        })
                      }
                    >
                      <div>
                        <h4 className="font-semibold">Set as Administrator</h4>
                        <p className="text-muted-foreground">
                          Don't associate this user with endpoints they interact
                          with
                        </p>
                      </div>
                    </DropdownMenuItem>
                  )}
                  {user.credentialsExposed && (
                    <DropdownMenuItem
                      onClick={() => updateUser({ credentialsExposed: false })}
                    >
                      Set credentials as not exposed
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
              <div>
                <h2 className="font-semibold">Ingested by Wirespeed At</h2>
                <p className="text-sm">{dateTime(user.createdAt)}</p>
              </div>
              <div>
                <h2 className="font-semibold">VIP</h2>
                <p className="text-sm">
                  {user.vip ? (
                    <CheckIcon className="text-green-500 h-6 w-6" />
                  ) : (
                    <XMarkIcon className="text-red-500 h-6 w-6" />
                  )}
                </p>
              </div>
              <div>
                <h2 className="font-semibold">Technical</h2>
                <p className="text-sm">
                  {user.technical ? (
                    <CheckIcon className="text-green-500 h-6 w-6" />
                  ) : (
                    <XMarkIcon className="text-red-500 h-6 w-6" />
                  )}
                </p>
              </div>
              <div>
                <h2 className="font-semibold">Administrator</h2>
                <p className="text-sm">
                  {user.administrator ? (
                    <CheckIcon className="text-green-500 h-6 w-6" />
                  ) : (
                    <XMarkIcon className="text-red-500 h-6 w-6" />
                  )}
                </p>
              </div>
              <div>
                <h2 className="font-semibold">Email</h2>
                <p className="text-sm">{user.email ?? "-"}</p>
              </div>
              <div>
                <h2 className="font-semibold">Username</h2>
                <p className="text-sm">{user.username ?? "-"}</p>
              </div>
              <div>
                <h2 className="font-semibold">Source ID</h2>
                <p className="text-sm">{user.directoryId ?? "-"}</p>
              </div>
              <div>
                <h2 className="font-semibold">Department</h2>
                <p className="text-sm">{user.department ?? "-"}</p>
              </div>
              <div>
                <h2 className="font-semibold">Title</h2>
                <p className="text-sm">{user.title ?? "-"}</p>
              </div>
              <div>
                <h2 className="font-semibold">First Seen At</h2>
                <p className="text-sm">{dateTime(user.createdAt)}</p>
              </div>
              {user.passwordLastChangedAt && (
                <div>
                  <h2 className="font-semibold">Password Last Changed</h2>
                  <p className="text-sm">
                    {dateTime(user.passwordLastChangedAt)}
                  </p>
                </div>
              )}
              {user.lastSignInAt && (
                <div>
                  <h2 className="font-semibold">Last Sign In</h2>
                  <p className="text-sm">{dateTime(user.lastSignInAt)}</p>
                </div>
              )}
              {user.updatedAt != null && (
                <div>
                  <h2 className="font-semibold">Updated At</h2>
                  <p className="text-sm">{dateTime(user.updatedAt)}</p>
                </div>
              )}
              {user.managerEmail && (
                <div>
                  <h2 className="font-semibold">Manager</h2>
                  <p className="text-sm">{user.managerEmail}</p>
                </div>
              )}
              {user.lastCheckedForCredentialExposures != null && (
                <div>
                  <h2 className="font-semibold flex items-center gap-1">
                    Credentials Exposed{" "}
                    <InformationCircleIcon
                      onClick={() =>
                        showArticle(DOCS.INTEGRATIONS.HAVE_I_BEEN_PWNED)
                      }
                      className="h-4 w-4 cursor-pointer"
                    />
                  </h2>
                  <p className="text-sm">
                    {user.credentialsExposed ? (
                      <CheckIcon className="text-green-500 h-6 w-6" />
                    ) : (
                      <XMarkIcon className="text-red-500 h-6 w-6" />
                    )}
                  </p>
                </div>
              )}
            </div>
          </CardContent>
          {user.lastCheckedForCredentialExposures != null && (
            <CardFooter className="text-xs text-muted-foreground">
              <div>
                Breach data provided by{" "}
                <a target="_blank" href="https://haveibeenpwned.com/">
                  haveibeenpwned.com
                </a>
              </div>
            </CardFooter>
          )}
        </Card>
        <TableCard
          onClickNavigate={(row) => ({
            to: "/cases/$caseId",
            params: { caseId: row.id },
          })}
          query={casesQuery}
          onUpdate={(settings) =>
            setCaseSearchSettings({ ...caseSearchSettings, ...settings })
          }
          headers={[
            {
              display: "ID",
              key: "sid",
              sortable: true,
            },
            {
              display: "Name",
              key: "name",
              sortable: true,
            },
            {
              display: "Status",
              key: "status",
              format: (value) => getStatusConfigByStatus(value)?.display,
            },
            {
              display: "Created At",
              key: "firstDetectionSourceDetectedAt",
              sortable: true,
              format(value) {
                return dateTime(value);
              },
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Cases</CardTitle>
            <CardDescription>
              Cases that this user has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
        <TableCard
          onClickNavigate={(row) => ({
            to: "/assets/endpoints/$endpointId",
            params: { endpointId: row.id },
          })}
          query={endpointsQuery}
          onUpdate={(settings) =>
            setCaseSearchSettings({ ...caseSearchSettings, ...settings })
          }
          headers={[
            { display: "Name", key: "name", sortable: true },
            { display: "Live", key: "live", sortable: true },
            {
              display: "Private IP",
              key: "privateIpAddress",
              sortable: true,
            },
            {
              display: "Public IP",
              key: "id",
              format(value, row) {
                return row.publicIPs?.map((v) => v.ipv4 ?? v.ipv6).join(", ");
              },
            },
            { display: "OS", key: "operatingSystem", sortable: true },
            { display: "Server", key: "server", sortable: true },
            { display: "Workstation", key: "workstation", sortable: true },
            { display: "HVA", key: "hva", sortable: true },
            {
              display: "HVA Locked",
              key: "hvaOverriddenByUser",
              info: ` A user has manually updated the HVA status of this row, therefore it is excluded from all automations. To remove this lock, select the 3-dot menu and click "Disable HVA Lock".`,
            },
            {
              display: "Source",
              key: "integrationPlatform",
              format: (v) => integrationMetadata.find((i) => i.slug == v)?.name,
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related Endpoints</CardTitle>
            <CardDescription>
              Endpoints that this user has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>

        <TableCard
          onClickNavigate={(row) => ({
            to: "/assets/ips/$ipId",
            params: { ipId: row.id },
          })}
          query={ipsQuery}
          searchable
          searchFilters={[
            {
              label: "Search Field",
              placeholder: "Select field",
              defaultValue: "ipv4",
              values: [
                { key: "ipv4", display: "IPv4" },
                { key: "ipv6", display: "IPv6" },
                { key: "service", display: "Service" },
                { key: "privacyService", display: "Privacy Service" },
              ],
              onSelect: (v: any) =>
                setIPSearchSettings({
                  ...ipSearchSettings,
                  page: 1,
                  search: ipSearchSettings?.search,
                  field: v,
                }),
            },
          ]}
          onUpdate={(settings) =>
            setIPSearchSettings({ ...ipSearchSettings, ...settings })
          }
          headers={[
            {
              display: "IP",
              key: "id",
              format: (_, row) => row.ipv4 ?? row.ipv6,
            },
            {
              display: "City",
              key: "id",
              format: (_, row) => row.metadata?.city ?? "-",
            },
            {
              display: "Region",
              key: "id",
              format: (_, row) => row.metadata?.region ?? "-",
            },
            {
              display: "Country",
              key: "id",
              format: (_, row) => row.metadata?.country ?? "-",
            },
            {
              display: "Privacy Services",
              key: "id",
              info: (
                <div>
                  <ul className="list-disc list-inside">
                    <li>hosting</li>
                    <li>vpn</li>
                    <li>proxy</li>
                    <li>tor</li>
                    <li>relay</li>
                  </ul>
                </div>
              ),
              format: (_, row) => {
                let values = Object.entries(row.metadata?.privacy ?? {})
                  .filter(([_, v]) => v == true && _ != "service")
                  .map(([k]) => k)
                  .join(", ");
                if (values == null || values.length == 0) {
                  return "-";
                }
                return values;
              },
            },
            {
              display: "Service",
              key: "id",
              format: (_, row) => {
                let service = row.metadata.privacy?.service;
                if (service == null || service.length == 0) {
                  return "-";
                }
                return service;
              },
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>Related IP Addresses</CardTitle>
            <CardDescription>
              IPs that this user has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>

        <TableCard
          query={userAgentsQuery}
          searchable
          onUpdate={(settings) =>
            setUserAgentSearchSettings({
              ...userAgentSearchSettings,
              ...settings,
            })
          }
          onClickNavigate={(row) => ({
            to: "/assets/user-agents/$userAgentId",
            params: { userAgentId: row.id },
          })}
          headers={[
            {
              display: "User Agent",
              key: "userAgent",
              format: (_, row) => row.userAgent,
            },
          ]}
        >
          <CardHeader className="bg-muted/50 mb-4">
            <CardTitle>User Agents</CardTitle>
            <CardDescription>
              User agents that this user has been associated with
            </CardDescription>
          </CardHeader>
        </TableCard>
      </div>
    </AppLayout>
  );
}
