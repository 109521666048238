import type { JSONSchema7 } from "json-schema";
import { ROLE } from "./types/shared.types";
export * from "./lib/flow/verdict-flow";
export * from "./lib/query/detection-query";
export * from "./types/shared.types";

export type JSONSchema = {
  type?: JSONSchema7["type"];
  const?: string;
  $ref?: string;
  format?: string;
  anyOf?: Partial<JSONSchema>[];
  example?: any;
  definitions?: {
    [key: string]: JSONSchema;
  };
  allOf?: Partial<JSONSchema>[];
  title?: string;
  additionalProperties?: JSONSchema | boolean;
  items?: JSONSchema;
  description?: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  nullable?: boolean;
  enum?: string[];
  oneOf?: Partial<JSONSchema>[];
  required?: string[];
  properties?: {
    [key: string]: JSONSchema;
  };
};

export * from "./lib/docs";
export function userHasRole(userRole: ROLE, requiredRole: ROLE) {
  if (requiredRole == userRole) return true;

  if (userRole == ROLE.ADMIN) {
    return true;
  }
  if (requiredRole == ROLE.VIEWER && userRole == ROLE.ANALYST) {
    return true;
  }
  return false;
}

export const ROLE_CONFIG = {
  [ROLE.ADMIN]: {
    name: "Admin",
    description: "Full access to all features",
  },
  [ROLE.ANALYST]: {
    name: "Analyst",
    description: "Access to all features",
  },
  [ROLE.VIEWER]: {
    name: "Viewer",
    description: "Limited access to features",
  },
};

export function oxfordComma(items?: string[]) {
  if (items == null || items.length === 0) {
    return "";
  }
  items = [...new Set(items)];
  if (items.length == 1) {
    return items[0];
  }
  if (items.length == 2) {
    return `${items[0]} and ${items[1]}`;
  }
  let last = items.pop();
  return `${items.join(", ")}, and ${last}`;
}

export function forEveryJSONProperty(
  obj: Record<string, any>,
  callback: (key: string, data: any) => any
) {
  let newObj = Object.assign({}, obj);
  for (const [key, value] of Object.entries(newObj)) {
    if (Array.isArray(value)) {
      for (const item of value) {
        newObj[key] = forEveryJSONProperty(item, callback);
      }
    } else if (typeof value === "object" && value != null) {
      newObj[key] = forEveryJSONProperty(value, callback);
    } else {
      newObj[key] = callback(key, value);
    }
  }
  return newObj;
}

export function slugify(str: string) {
  return str.toLowerCase().replace(/[^a-z0-9]/g, "-");
}

export function enumify(str: string) {
  return slugify(str).toUpperCase().replace(/-/g, "_");
}
