import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { apiClient } from "@/lib/api";
import { PlusIcon } from "@radix-ui/react-icons";
import { useRouteContext } from "@tanstack/react-router";
import { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";

export default function AddAdminIntegration(props: {
  hideTrigger?: boolean;
  open?: boolean;
  onChange?: (open: boolean) => void;
}) {
  const { integrationMetadata } = useRouteContext({
    from: "/_application",
  });
  const [clientKey, setClientKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [secondaryClientKey, setSecondaryClientKey] = useState("");
  const [secondarySecretKey, setSecondarySecretKey] = useState("");
  const [storeId, setStoreId] = useState("");
  const [stateSecret, setStateSecret] = useState("");
  const [signingSecret, setSigningSecret] = useState("");
  const [open, setOpen] = useState(props.open ?? false);
  const [integrationType, setIntegrationType] = useState<string>();

  useEffect(() => {
    props.onChange?.(open);
  }, [open]);

  useEffect(() => {
    if (props.open != null) {
      setOpen(props.open);
    }
  }, [props.open]);

  const integrations = useMemo(() => {
    return integrationMetadata.map((metadata) => (
      <SelectItem value={metadata.slug} key={metadata.slug}>
        {metadata.name}
      </SelectItem>
    ));
  }, [integrationMetadata]);

  async function add() {
    if (integrationType == null) return;

    const response = await apiClient.PUT("/admin/integration", {
      body: {
        type: integrationType as any,
        clientKey,
        secretKey,
        storeId,
        secondaryClientKey,
        secondarySecretKey,
        signingSecret,
        stateSecret,
      },
    });
    if (response.error != null) {
      toast.error(response.error.message);
      return;
    }
    toast.success("Integration keys added");
    setClientKey("");
    setSecretKey("");
    setSigningSecret("");
    setStateSecret("");
    setSecondaryClientKey("");
    setSecondarySecretKey("");
    setStoreId("");
    setIntegrationType(undefined);

    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!props.hideTrigger && (
        <DialogTrigger asChild>
          <Button>
            <PlusIcon className="h-5 w-5 mr-2" />
            Add new integration
          </Button>
        </DialogTrigger>
      )}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add new integration</DialogTitle>
          <DialogDescription>
            Configure the client keys for a new integration.
          </DialogDescription>
        </DialogHeader>
        <div>
          <div className="flex flex-col space-y-4">
            <div>
              <Label>Type</Label>
              <Select onValueChange={(e) => setIntegrationType(e)}>
                <SelectTrigger>
                  <SelectValue placeholder="Select an integration" />
                </SelectTrigger>
                <SelectContent>{integrations}</SelectContent>
              </Select>
            </div>
            {integrationType && (
              <div className="space-y-4">
                <IntegrationInput
                  label="Client Key"
                  value={clientKey}
                  onChange={setClientKey}
                />
                <IntegrationInput
                  label="Secret Key"
                  value={secretKey}
                  secret
                  onChange={setSecretKey}
                />
                <IntegrationInput
                  label="Signing Secret"
                  value={signingSecret}
                  secret
                  onChange={setSigningSecret}
                />
                <IntegrationInput
                  label="State Secret"
                  value={stateSecret}
                  secret
                  onChange={setStateSecret}
                />
                <IntegrationInput
                  label="Store ID"
                  value={storeId}
                  secret
                  onChange={setStoreId}
                />
                <IntegrationInput
                  label="Secondary Client Key"
                  value={secondaryClientKey}
                  secret
                  onChange={setSecondaryClientKey}
                />
                <IntegrationInput
                  label="Secondary Secret Key"
                  value={secondarySecretKey}
                  secret
                  onChange={setSecondarySecretKey}
                />
              </div>
            )}
            <Button onClick={() => add()} className="self-end">
              Add
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function IntegrationInput(props: {
  label: string;
  value: string;
  secret?: boolean;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <div>
      <Label>{props.label}</Label>
      <Input
        value={props.value}
        type={props.secret ? "password" : "text"}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  );
}
