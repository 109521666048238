import { createFileRoute, useRouteContext } from "@tanstack/react-router";

import { apiClient } from "@/lib/api";
import {
  keepPreviousData,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";

import SPDashboard from "@/components/dashboard/sp-dashboard";
import UserDashboard from "@/components/dashboard/user-dashboard";
import { components } from "@/lib/api.types";

export const Route = createFileRoute("/_application/dashboard")({
  component: Dashboard,
  loader: ({ context }) => {
    void context.queryClient.ensureQueryData(getOptions());
  },
});

export interface DashboardData {
  caseCount: components["schemas"]["Count"];
  weeklyCases: components["schemas"]["WeeklyCount"][];
  mttr: components["schemas"]["TimeAverageAndChange"];
  mttd: components["schemas"]["TimeAverageAndChange"];
  mttv: components["schemas"]["TimeAverageAndChange"];
  closedCases: components["schemas"]["Cases"];
  recentVIPs: components["schemas"]["DirectoryUserSearchPage"];
  integrations: components["schemas"]["IntegrationSearch"];
}

async function getData(): Promise<DashboardData> {
  const [
    caseCount,
    weeklyCases,
    mttr,
    mttd,
    mttv,
    closedCases,
    recentVIPs,
    integrations,
  ] = await Promise.all([
    apiClient.POST("/cases/count", { body: { status: "ESCALATED" } }),
    apiClient.GET("/cases/count/weekly"),
    apiClient.GET("/detection/mttr"),
    apiClient.GET("/detection/mttd"),
    apiClient.GET("/detection/mttv"),
    apiClient.POST("/cases", {
      body: { statuses: ["CLOSED"], orderBy: "closedAt", orderDir: "desc" },
    }),
    apiClient.POST("/directory", {
      body: { vipOnly: true, orderBy: "createdAt" },
    }),
    apiClient.POST("/integration", { body: { size: 1000 } }),
  ]);
  if (
    caseCount.error != null ||
    weeklyCases.error != null ||
    closedCases.error != null ||
    mttr.error != null ||
    mttv.error != null ||
    mttd.error != null ||
    recentVIPs.error != null ||
    integrations.error != null
  ) {
    throw new Error("Error getting dashboard information");
  }
  return {
    caseCount: caseCount.data,
    weeklyCases: weeklyCases.data,
    closedCases: closedCases.data,
    mttr: mttr.data,
    mttd: mttd.data,
    mttv: mttv.data,
    recentVIPs: recentVIPs.data,
    integrations: integrations.data,
  };
}

export const DASHBOARD_QUERY_KEY = "dashboard";
const getOptions = () =>
  queryOptions({
    queryKey: [DASHBOARD_QUERY_KEY],
    queryFn: () => getData(),
    placeholderData: keepPreviousData,
  });

export default function Dashboard() {
  const { team } = useRouteContext({ from: "/_application" });
  const { data } = useSuspenseQuery(getOptions());
  if (team.serviceProvider) {
    return <SPDashboard {...data} />;
  }
  return <UserDashboard {...data} />;
}
