"use client";
import { Button } from "@/components/ui/button";
import { apiClient } from "@/lib/api";
import { components } from "@/lib/api.types";
import {
  INTEGRATION_LOG_DAILY_QUERY_KEY,
  INTEGRATION_LOG_QUERY_KEY,
} from "@/routes/_application/settings/integrations/$integrationId";
import { useQueryClient } from "@tanstack/react-query";
import { ROLE } from "@wire/shared";
import { useState } from "react";
import { toast } from "sonner";

export default function ToggleIntegration({
  integration,
  onComplete,
}: {
  integration: components["schemas"]["IntegrationV2"];
  onComplete: () => void;
}) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(integration.enabled);

  async function update(enabled: boolean) {
    setLoading(true);
    const promise = apiClient.PATCH("/integration/{id}", {
      params: { path: { id: integration.id } },
      body: { enabled },
    });
    toast.promise(promise, {
      loading: enabled ? "Enabling integration..." : "Disabling integration...",
    });
    const response = await promise;
    if (response.error != null) {
      toast.error((response as any).error.message);
      return;
    }
    setEnabled(enabled);
    toast.success(enabled ? "Integration enabled" : "Integration disabled");
    onComplete();
    setLoading(false);
    await queryClient.invalidateQueries({
      queryKey: [INTEGRATION_LOG_DAILY_QUERY_KEY],
    });
    await queryClient.invalidateQueries({
      queryKey: [INTEGRATION_LOG_QUERY_KEY],
    });
  }

  if (enabled) {
    return (
      <Button
        requiredRole={ROLE.ADMIN}
        disabled={loading}
        variant="outlineDestructive"
        onClick={() => update(false)}
      >
        Disable Integration
      </Button>
    );
  } else {
    return (
      <Button requiredRole={ROLE.ADMIN} onClick={() => update(true)}>
        Enable Integration
      </Button>
    );
  }
}
