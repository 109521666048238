import { DocsLink } from "@/components/settings/add-integration";
import { Button } from "@/components/ui/button";
import { components } from "@/lib/api.types";
import { cn } from "@/lib/utils";
import { showArticle } from "@intercom/messenger-js-sdk";
import { DOCS } from "@wire/shared";
import copy from "copy-to-clipboard";
import { toast } from "sonner";

export function OAuthButton(props: {
  url?: string;
  onComplete?: () => void;
  title: string;
  className?: string;
  error?: string;
  metadata: components["schemas"]["IntegrationMetadataConfigV2"];
}) {
  function copyLink() {
    if (!props.url) return;
    copy(props.url);
    toast.success("URL copied to clipboard", {
      action: {
        label: "More info",
        onClick: () => showArticle(DOCS.FAQ),
      },
    });
    props.onComplete?.();
  }

  return (
    <div className={cn("space-y-2", props.className)}>
      <div className="flex flex-row space-x-2">
        <Button onClick={props.onComplete} asChild>
          <a href={props.url} target="_blank">
            {props.title}
          </a>
        </Button>
        <Button onClick={copyLink} variant="outline">
          Copy Link for Admin
        </Button>
        <DocsLink metadata={props.metadata} />
      </div>
      {props.error && <p className="text-sm text-red-500">{props.error}</p>}
    </div>
  );
}
